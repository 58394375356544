import getData from "@/__main__/get-data.mjs";
import * as API from "@/game-deadlock/api.mjs";
import HeroesStats from "@/game-deadlock/models/heroesStats.mjs";

export default function fetchData(
  _params,
  _searchParam: URLSearchParams,
): void {
  getData(
    API.getHeroesStats({ gameMode: "Normal", matchMode: "Unranked" }),
    HeroesStats,
    ["deadlock", "heroesStats"],
    {
      shouldFetchIfPathExists: true,
    },
  );
}
